<template>
  <div class="row">
    <div class="flex xs12">
      <va-card>
        <div class="row align--center">
          <div class="flex xs12 md10">
            <h4>{{ $t('reports.new') }}</h4>
          </div>
          <div class="flex xs12 md2 pull-right">
            <va-button
              color="primary"
              icon="fa fa-plus"
              :to="{ name: 'reportsNew' }"
            >
              {{ $t('tables.actions.new') }}
            </va-button>
          </div>
        </div>
      </va-card>
    </div>
    <div class="flex xs12">
      <report-summary-index
        v-if="currentUser.can('RegionReports', 'index')"
        :label="$t('reports.presentations.regions')"
        :loading="loading.region"
        :table-fields="regionFields"
        :action-buttons="actionButtons"
        :table-filters="regionFilters"
        crud-links="regionReports"
        reports-route="reports/regions"
      />
    </div>
    <div class="flex xs12">
      <report-summary-index
        v-if="currentUser.can('CountryReports', 'index')"
        :label="$t('reports.presentations.countries')"
        :loading="loading.country"
        :table-fields="countryFields"
        :action-buttons="actionButtons"
        :table-filters="countryFilters"
        crud-links="countryReports"
        reports-route="reports/countries"
      />
    </div>
    <div class="flex xs12">
      <report-summary-index
        v-if="currentUser.can('Proyections', 'index')"
        :label="$t('reports.presentations.teams')"
        :loading="loading.district"
        :table-fields="districtFields"
        :top-buttons="topButtons"
        :action-buttons="actionButtons"
        :table-filters="districtFilters"
        crud-links="reports"
        controller="Proyections"
        reports-route="reports/districts"
      />
    </div>
    <div
      class="flex xs12"
      v-if="tabs.length > 0"
    >
      <va-card>
        <tabs-container :tabs="tabs">
          <template v-slot:region>
            <monthly-bar
              selection="region"
              :loading="loading.region"
              @submit="regionReport"
            />
            <monthly-report
              v-show="show.region"
              :loading="loading.monthly.region"
              :reports-list="tableData.reports.region"
              @export="prepareDownload('region')"
            />
          </template>
          <template v-slot:country>
            <monthly-bar
              selection="country"
              :loading="loading.country"
              @submit="countryReport"
            />
            <monthly-report
              v-show="show.country"
              :loading="loading.monthly.country"
              :reports-list="tableData.reports.country"
              @export="prepareDownload('country')"
            />
          </template>
          <template v-slot:district>
            <monthly-bar
              selection="district"
              :loading="loading.district"
              @submit="districtReport"
            />
            <monthly-report
              v-show="show.district"
              :loading="loading.monthly.district"
              :reports-list="tableData.reports.district"
              @export="prepareDownload('district')"
            />
          </template>
        </tabs-container>
      </va-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const TabsContainer = () => import(/* webpackPrefetch: true */ '@/components/extras/TabsContainer')
const MonthlyBar = () => import(/* webpackPrefetch: true */ '@/components/extras/Bars/Monthly')
const MonthlyReport = () => import(/* webpackPrefetch: true */ './monthly/Report')
const ReportSummaryIndex = () => import(/* webpackPrefetch: true */ './summary/Index')

export default {
  name: 'reports-index',
  components: {
    TabsContainer,
    MonthlyBar,
    MonthlyReport,
    ReportSummaryIndex,
  },
  data () {
    return {
      tableData: {
        reports: {
          region: [],
          country: [],
          district: [],
        },
      },
      show: {
        region: false,
        country: false,
        district: false,
      },
      loading: {
        region: false,
        country: false,
        district: false,
        monthly: {
          region: false,
          country: false,
          district: false,
        },
      },
      currentQuery: {
        region: {},
        country: {},
        district: {},
      },
      topButtons: ['new'],
      actionButtons: ['view', 'delete'],
      regionFilters: ['regions', 'dateSince', 'dateUntil'],
      countryFilters: ['regions', 'countries', 'dateSince', 'dateUntil'],
      districtFilters: ['regions', 'countries', 'districts', 'dateSince', 'dateUntil'],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    districtFields () {
      return [
        {
          name: 'proyection_date',
          title: this.$t('tables.headings.date'),
          callback: this.dateLabel,
          sortField: 'proyections_date',
        },
        {
          name: 'team.code',
          title: this.$t('tables.headings.code'),
          sortField: 'Teams.code',
        },
        {
          name: 'district.country.name',
          title: this.$t('tables.headings.country'),
          visible: !this.currentUser.can('Countries', 'index'),
          sortField: 'Countries.name',
          callback: this.translatedName,
        },
        {
          name: 'district.name',
          title: this.$t('tables.headings.district'),
          sortField: 'Districts.name',
          callback: this.translatedName,
        },
        {
          name: 'approval',
          title: this.$t('tables.headings.district_approved'),
          callback: this.approvalLabel,
          sortField: 'approval',
        },
        {
          name: 'presentations',
          title: this.$t('reports.modal.images'),
          callback: this.imagesLabel,
          sortField: 'images',
        },
      ]
    },
    countryFields () {
      return [
        {
          name: 'proyection_date',
          title: this.$t('tables.headings.date'),
          callback: this.dateLabel,
          sortField: 'proyection_date',
        },
        {
          name: 'country.name',
          title: this.$t('tables.headings.country'),
          visible: this.currentUser.can('Countries', 'index'),
          sortField: 'Countries.name',
          callback: this.translatedName,
        },
        {
          name: 'approval',
          title: this.$t('tables.headings.country_approved'),
          callback: this.approvalLabel,
          sortField: 'approval',
        },
      ]
    },
    regionFields () {
      return [
        {
          name: 'proyection_date',
          title: this.$t('tables.headings.date'),
          callback: this.dateLabel,
          sortField: 'proyection_date',
        },
        {
          name: 'region.name',
          title: this.$t('tables.headings.region'),
          visible: this.currentUser.can('Regions', 'index'),
          sortField: 'Regions.name',
          callback: this.translatedName,
        },
        {
          name: 'approval',
          title: this.$t('tables.headings.region_approved'),
          callback: this.approvalLabel,
          sortField: 'approval',
        },
      ]
    },
    tabs () {
      const t = []
      if (this.currentUser.can('Regions', 'index')) {
        t.push({ title: this.$t('reports.generate.region'), name: 'region' })
      }
      if (this.currentUser.can('Countries', 'index')) {
        t.push({ title: this.$t('reports.generate.country'), name: 'country' })
      }
      if (this.currentUser.can('Districts', 'index')) {
        t.push({ title: this.$t('reports.generate.district'), name: 'district' })
      }
      return t
    },
  },
  methods: {
    translatedName (name) {
      return this.$t(name)
    },
    regionReport (data) {
      const d = {
        type: 'region',
        date: data.date,
        id: data.region,
      }
      this.getMonthlyReport(d)
    },
    countryReport (data) {
      const d = {
        type: 'country',
        date: data.date,
        id: data.country,
      }
      this.getMonthlyReport(d)
    },
    districtReport (data) {
      const d = {
        type: 'district',
        date: data.date,
        id: data.district,
      }
      this.getMonthlyReport(d)
    },
    async getMonthlyReport (data) {
      this.currentQuery[data.type] = data
      this.loading.monthly[data.type] = true
      this.show[data.type] = true

      let response = false
      try {
        response = await this.$http.post('proyections/monthly', data)
      } catch (e) {
        // console.log('Cant get reports', e)
        this.loading.monthly[data.type] = false
        return
      }

      this.tableData.reports[data.type] = response.data.data || []
      this.loading.monthly[data.type] = false
    },
    approvalLabel (approval) {
      let a = 'tables.status.unreviewed'
      switch (approval) {
        case 1: a = 'tables.status.approved'
          break
        case -1: a = 'tables.status.rejected'
          break
      }

      return this.$t(a)
    },
    imagesLabel (p) {
      // let url = process.env.API_URL || 'http://localhost:8765/api'
      // let url = process.env.API_URL || 'https://dev.rpts.jfhp.org/api'
      let url = process.env.API_URL || 'https://rpts.jfhp.org/api'
      url = url.replace('/api', '')
      const BASE_URL = url
      if (p.length > 0) {
        const id = p[0].id
        if (p[0].presentation_images[0]) {
          return '<a target="_blank" href="' + BASE_URL + '/presentations/download-images/' + id + '">' + this.$t('tables.headings.download') + '</a>'
          // return '<a href="https://rpts.jfhp.org/presentations/download-images/' + id + '">' + this.$t('tables.headings.download') + '</a>'
        }
      }
    },
    dateLabel (date) {
      return this.$date.format(date, 'MMMM - yyyy')
    },
    async prepareDownload (type) {
      const prepareRoute = 'proyections/monthly?export=xlsx'
      const downloadRoute = 'proyections/download/'

      return this.downloadFile(this.currentQuery[type], prepareRoute, downloadRoute)
    },
    async downloadFile (filters, prepareRoute, downloadRoute) {
      let fileName = ''
      const prepare = {
        icon: 'info',
        title: this.$t('notifications.download.prepare'),
        confirmButtonText: this.$t('notifications.download.button'),
        text: this.$t('notifications.download.wait'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !this.$swal.isLoading(),
        preConfirm: async () => {
          let data = false
          try {
            data = await this.$http.post(prepareRoute, filters)
          } catch (e) {
            this.$swal.insertQueueStep(error)
            return
          }

          fileName = data.data.data
          this.$swal.insertQueueStep(download)
        },
      }
      const error = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.error'),
      }
      const download = {
        icon: 'success',
        title: this.$t('notifications.download.downloading'),
        allowOutsideClick: () => !this.$swal.isLoading(),
        onOpen: async () => {
          this.$swal.showLoading()
          let response = false
          try {
            response = await this.$http.get(downloadRoute + fileName, {
              responseType: 'blob',
            })
          } catch (e) {
            // console.log('Cant download file', e)
            await this.$swal.queue([retry])
            return
          }

          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()

          this.$swal.close()
        },
      }
      const retry = {
        icon: 'error',
        title: 'Error',
        text: this.$t('notifications.download.failed'),
        confirmButtonText: this.$t('notifications.download.retry'),
        allowOutsideClick: true,
        preConfirm: () => {
          this.$swal.insertQueueStep(download)
        },
      }

      this.$swal.queue([prepare])
    },
  },
}
</script>
